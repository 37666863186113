(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var removeVideoId = function removeVideoId() {
    // Removing video-js as id.
    var all = document.querySelectorAll('#video-js');
    all.forEach(function (video, i) {
        all[i].removeAttribute('id');
    });
    // Removing #vjs_video_3 as id.
    all = document.querySelectorAll('#vjs_video_3');
    all.forEach(function (video, i) {
        //all[i].removeAttribute('id');
    });
};
removeVideoId();

var addVideoTitleAttr = function addVideoTitleAttr() {
    var all = document.querySelectorAll('video');
    all.forEach(function (video, i) {
        if (0 == video.title.length) {
            video.title = 'Embedded Video';
        }
    });
};
addVideoTitleAttr();

var addNameAttr = function addNameAttr() {
    setTimeout(function () {
        var dealer = document.querySelector('#dealer');
        if (dealer) {
            dealer.name = 'dealer';
        }
    }, 1000);
};
addNameAttr();

var addButtonTitle = function addButtonTitle() {
    var all = document.querySelectorAll('.vjs-errors-ok-button');
    all.forEach(function (button, i) {
        all[i].title = 'VJS Error Button';
    });
};
addButtonTitle();

var addMapBoxTitle = function addMapBoxTitle() {
    setTimeout(function () {
        var mapboxCanvas = document.querySelector('.mapboxgl-canvas');
        if (mapboxCanvas) {
            mapboxCanvas.title = 'Interactive Dealer Map';
        }
    }, 500);
};
addMapBoxTitle();

},{}]},{},[1]);
